<script setup lang="ts">
import * as yup from 'yup'
import { ServerStatusCode } from '~/enums/server'
import type { FormErrorEvent } from '#ui/types'

const props = defineProps({
	isOpen: {
		type: Boolean,
		default: false
	},
	onComplete: {
		type: Function,
		default: () => ({})
	}
})

const emits = defineEmits([
	'update:isOpen'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const toast = useToast()
const villageStore = useVillageStore()

const localIsOpen = computed({
	get: () => props.isOpen,
	set: (value) => emits('update:isOpen', value)
})

const schema = yup.object().shape({
	title: yup
		.string()
		.required(t('message.title.empty')),
})

const state = reactive({
	title: undefined,
})

const form = ref()
const isLoading = ref(false)

const isValid = computed(() => schema.isValidSync(state))

const onSubmit = async () => {
	isLoading.value = true
	
	const result = await villageStore.sendRequest(state)
	
	isLoading.value = false
	
	const error = result.error?.value
	if (error) {
		onSubmitError(error)
		return
	}
	
	toast.add({
		id: 'success',
		title: t('message.success.title'),
		description: t('message.success.description'),
		timeout: 2000,
		color: 'green',
	})
	
	localIsOpen.value = false
	
	state.title = undefined
	
	props.onComplete(result.data.value)
}

const onSubmitError = (error: any) => {
	const errors: any[] = []
	switch (error.statusCode) {
		case ServerStatusCode.UnprocessableContent:
			Object.entries(error.data.errors).forEach(([key, value]) => {
				errors.push({
					path: key,
					message: (value as Array<string>).join('')
				})
			})
			break
		case ServerStatusCode.ToManyRequests:
			toast.add({
				id: 'error',
				title: tg('error.title'),
				description: tg('error.many_requests', {
					seconds: tg('unit.sec_plural', parseInt((error.cause as any).response.headers.get('Retry-After')))
				}),
				timeout: 2000,
				color: 'red',
				icon: 'i-heroicons-exclamation-triangle-16-solid',
			})
			break
		default:
			if (error.value?.data?.message) {
				toast.add({
					id: 'error',
					title: tg('error.title'),
					description: error.value.data.message,
					timeout: 2000,
					color: 'red',
					icon: 'i-heroicons-exclamation-triangle-16-solid',
				})
			}
			break
	}
	
	if (errors.length) {
		form.value.setErrors(errors)
	}
}

const onError = (event: FormErrorEvent) => {
	const element = document.getElementById(event.errors[0].id)
	element?.focus()
	element?.scrollIntoView({
		behavior: 'smooth',
		block: 'center'
	})
}

const onCancel = () => {
	localIsOpen.value = false
}

</script>

<template>
	<UiModal
		v-model="localIsOpen"
		:fullscreen="$isMobile() && !$isTablet()"
		:center="false"
	>
		<div class="flex flex-col px-4 py-5 md:py-9 md:px-14">
			<div
				class="mb-4 mr-6 text-center text-gray-600 text-xl font-semibold"
			>
				{{ t('title') }}
			</div>
			<UForm
				ref="form"
				:schema="schema"
				:state="state"
				:validate-on="['submit', 'change', 'input']"
				@submit="onSubmit"
				@error="onError"
			>
				<UFormGroup
					name="title"
					class="mt-0"
				>
					<UInput
						v-model="state.title"
						size="md"
						:placeholder="t('placeholder.title')"
						:ui="{
							form: '!bg-white'
						}"
					/>
				</UFormGroup>
				
				<div class="mt-2 text-sm text-gray-500">
					{{ t('text') }}
				</div>
				
				<div class="flex gap-2 items-center justify-center mt-2">
					<UButton
						variant="outline"
						size="xl"
						class="mt-4"
						:label="t('button.cancel')"
						:ui="{
							font: 'font-semibold',
							padding: {
								xl: 'px-3.5 py-3.5'
							}
						}"
						@click="onCancel"
					/>
					<UButton
						type="submit"
						size="xl"
						class="mt-4"
						:label="t('button.submit')"
						:ui="{
							font: 'font-semibold',
							padding: {
								xl: 'px-3.5 py-3.5'
							}
						}"
						:disabled="isLoading || !isValid"
					>
						<template #leading>
							<UIcon
								v-if="isLoading"
								name="i-svg-spinners-ring-resize"
								class="w-5 h-5"
								dynamic
							/>
						</template>
					</UButton>
				</div>
			</UForm>
		</div>
	</UiModal>
</template>

<i18n>
{
	"ru": {
		"title": "Введите название поселка или ЖК",
		"placeholder": {
			"title": "Название поселка или ЖК"
		},
		"text": "После проверки, наша служба модерации добавит его в каталог и привяжет к нему ваше объявление. Если у нас возникнут вопросы, мы свяжемся с вами.",
		"button": {
			"submit": "Отправить",
			"cancel": "Отменить"
		},
		"message": {
			"success": {
				"title": "Спасибо!",
				"description": "Ваш запрос был успешно отправлен"
			},
			"title": {
				"empty": "Вы не указали название поселка"
			}
		}
	}
}
</i18n>